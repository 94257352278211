import React from 'react';
import {graphql} from 'gatsby';
import Layout from '../../components/layout';
import PageContent from '../../components/PageContent';
import SEO from "../../components/SEO";

export default function SinglePage({data, pageContext}) {
  return (
    <>
      <SEO seoData={data.page.seo}/>
      <Layout language={pageContext.language}>
        <PageContent data={data}/>
      </Layout>
    </>
  );
}

export const query = graphql`
    query page($id: String!) {
        page: wpPage(id: { eq: $id }) {
            title
            content
            featuredImage {
                node {
                    remoteFile {
                        ...Thumbnail
                    }
                }
            }
            seo {
                metaDesc
                metaKeywords
                metaRobotsNoindex
                metaRobotsNofollow
                title
                opengraphSiteName
                opengraphDescription
                opengraphImage {
                    sourceUrl
                }
            }
        }
    }
`;
